
export const balanceCols = [
  {
    prop: 'tradeTime',
    label: '操作时间',
    minWidth: '200',
    slotName: 'tradeTimeSlot',
    sortable: true
  },
  {
    prop: 'tradeAmount',
    label: '本次变更额度',
    minWidth: '200',
    slotName: 'tradeAmount'
  },
  {
    prop: 'tradeTitle',
    label: '备注',
    minWidth: '200'
  }
 
]
